import "./EditEmailModalSectionWrapper.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { useConfig } from "../../../utils/hook"
import HelpCenter from "../HelpCenter"

const EditEmailModalSectionWrapper = ({
  children,
  title,
  description,
  helpCenter,
  withoutBottomMargin,
}) => {
  const config = useConfig()

  const { whitelabelId } = useSelector(({ user }) => user.profile)

  const editEmailModalSectionWrapperClass = classNames("edit-email-modal-section-wrapper", {
    "without-bottom-margin": withoutBottomMargin,
  })
  return (
    <div className={editEmailModalSectionWrapperClass}>
      <div className="edit-email-modal-section-wrapper__left">
        <div className="large">{title}</div>
        <div className="small-description">{description}</div>
        {helpCenter && !(!whitelabelId || config.REACT_APP_HIDE_EXPLANATION_VIDEOS) && (
          <HelpCenter link={helpCenter.link} helpLabel={helpCenter.label} />
        )}
      </div>
      <div className="edit-email-modal-section-wrapper__right">{children}</div>
    </div>
  )
}

EditEmailModalSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  helpCenter: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
  withoutBottomMargin: PropTypes.bool,
}

EditEmailModalSectionWrapper.defaultProps = {
  title: "",
  description: "",
  helpCenter: undefined,
  withoutBottomMargin: false,
}

export default EditEmailModalSectionWrapper
