import "./Search.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import SvgIcon from "../SvgIcon"

const Search = ({
  field,
  value,
  onChange,
  placeholder,
  disabled,
  onEnter,
  searchClassNames,
  showClear,
  autoFocus,
}) => {
  const inputRef = useRef(null)
  const dispatch = useDispatch()
  const [localValue, setLocalValue] = useState(value)

  const searchClassName = classNames("search-container", {
    "search-container__disabled": disabled,
    "search-container__clear-search": showClear && localValue.length > 0,
    [searchClassNames]: searchClassNames,
  })

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleOnChange = event => {
    const inputValue = event.target.value
    setLocalValue(inputValue)

    if (onChange) {
      onChange(inputValue)
    }

    if (field) {
      dispatch(updateFormField(field, inputValue))
    }
  }

  const handleClear = () => {
    setLocalValue("")

    if (onChange) {
      onChange("")
    }

    if (field) {
      dispatch(updateFormField(field, ""))
    }
  }

  const handlePaste = event => {
    event.preventDefault()
    const pastedText = event.clipboardData.getData("Text")

    let newValue
    if (localValue) {
      newValue = localValue + pastedText
    } else {
      newValue = pastedText
    }

    setLocalValue(newValue)

    if (field) {
      dispatch(updateFormField(field, newValue))
    }

    if (onChange) {
      onChange(newValue)
    }
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (onEnter) {
        onEnter(event.target.value)
      }
    }
  }

  return (
    <div className={searchClassName}>
      <SvgIcon icon="search-20" className="search-icon" />
      <input
        ref={inputRef}
        type="text"
        className="search-container__input"
        value={localValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        disabled={disabled}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
      />
      {showClear && localValue.length > 0 && (
        <SvgIcon className="clear-search-icon" icon="clear-search-20" onClick={handleClear} />
      )}
    </div>
  )
}

Search.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onEnter: PropTypes.func,
  searchClassNames: PropTypes.string,
  showClear: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

Search.defaultProps = {
  field: undefined,
  value: undefined,
  onChange: undefined,
  placeholder: undefined,
  disabled: false,
  onEnter: undefined,
  searchClassNames: undefined,
  showClear: false,
  autoFocus: false,
}

export default Search
