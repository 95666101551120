import "./InlineNotification.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../../atoms/SvgIcon"

export const InlineNotificationVariant = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
}

const InlineNotification = ({ label, type, className }) => {
  const inlineNotificationClassName = classNames("inline-notification-wrapper", {
    info: type === "info",
    success: type === "success",
    warning: type === "warning",
    error: type === "error",
    [className]: className,
  })

  const getInlineNotificationIcon = () => {
    switch (type) {
      case InlineNotificationVariant.SUCCESS:
        return "check-28"
      case InlineNotificationVariant.WARNING:
        return "alert-triangle-28"
      case InlineNotificationVariant.ERROR:
        return "circle-half-diagonal-28"
      case InlineNotificationVariant.INFO:
      default:
        return "info-circle-28"
    }
  }

  return (
    <div className={inlineNotificationClassName}>
      <div className="rectangle" />
      <div className="inline-notification-icon-container">
        <SvgIcon icon={getInlineNotificationIcon()} className="inline-notification-icon" />
      </div>
      <div className="inline-notification-description large">
        <span>{label}</span>
      </div>
    </div>
  )
}

InlineNotification.propTypes = {
  label: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

InlineNotification.defaultProps = {
  className: "",
}

export default InlineNotification
